import { Box, Button, Grid, Typography } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import Loader from 'src/components/Loader'
import { GridColumn as KendoGridColumn } from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import SectionCard from 'src/components/SectionCard'
import { ColumnMenu } from 'src/components/Filters/HeaderFilter'
import { State } from '@progress/kendo-data-query'
import { GetApp } from '@material-ui/icons'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { getColumns } from './CorporateSOPColumns'

const CorporateSOP: React.FC = () => {
  const [cardType, setCardType] = useState('master')
  const userToken = GetAccessToken()
  const grid = useRef<any>(null)
  const [columns, setColumns] = useState<any[]>([])
  let exportedData: any

  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const exportData = (dataToExport: any) => {
    if (dataToExport && dataToExport.length > 0) {
      exportedData.save(dataToExport, columns)
    } else {
      enqueueSnackbar('There is no data to be exported', notifyError)
    }
  }

  const getCorporateSOPCards = useQuery({
    queryKey: ['getCorporateSOPCards'],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getCorporateSOPCards`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const corporateCardData = getCorporateSOPCards?.data || []

  const getCorporateSOPGrid = useQuery({
    enabled: cardType !== '',
    queryKey: ['getCorporateSOPGrid', cardType, gridState],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getCorporateSOPGrid`,
        params: {
          cardType,
          pagination: encodeURIComponent(JSON.stringify(gridState)),
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const corporateGridData = getCorporateSOPGrid?.data || []

  const handleCardType = (card: string) => {
    setCardType(card)
  }

  const loading =
    getCorporateSOPCards.isLoading || getCorporateSOPGrid.isLoading

  useEffect(() => {
    if (cardType !== '') setColumns(getColumns(cardType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType])

  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Corporate SOP</Typography>
          </Grid>
        </Grid>

        <Box mt={10} style={{ justifyContent: 'center' }}>
          {corporateCardData.isLoading && <Loader />}
          <Grid container style={{ justifyContent: 'center' }}>
            {cardType !== '' && !corporateCardData.isLoading && (
              <Grid item xs={12} md={12}>
                <SectionCard
                  cards={corporateCardData || []}
                  onCardClick={handleCardType}
                  selected={cardType}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box mt={10}>
          <ExcelExport
            data={corporateGridData?.files}
            fileName={`${cardType}.xlsx`}
            // eslint-disable-next-line no-return-assign
            ref={(exporter) => (exportedData = exporter)}
          >
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="right">
                <Button
                  title="Export Excel"
                  onClick={() => exportData(corporateGridData.files)}
                  startIcon={<GetApp />}
                  disabled={!corporateGridData}
                >
                  Export to Excel
                </Button>
              </Box>

              {loading && <Loader />}
              {cardType !== '' && (
                <Box mt={2}>
                  <KendoGrid
                    ref={grid}
                    scrollable="scrollable"
                    data={corporateGridData?.files ?? []}
                    skip={gridState.skip}
                    take={gridState.take}
                    pageSize={gridState.take}
                    filter={gridState.filter}
                    sort={gridState.sort}
                    sortable
                    pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                    total={corporateGridData?.total ?? 0}
                    onDataStateChange={(e) => {
                      setGridState(e.dataState)
                    }}
                    style={{ minHeight: '200px', maxHeight: '500px' }}
                  >
                    {columns.reduce((acc, item, idx) => {
                      const component = [...acc]

                      item.show &&
                        component.push(
                          <KendoGridColumn
                            key={item.field + item.title}
                            field={item.field}
                            title={item.title}
                            cell={item.cell}
                            width={item.width}
                            columnMenu={ColumnMenu}
                          />
                        )

                      return component
                    }, [])}
                  </KendoGrid>
                </Box>
              )}
            </Box>
          </ExcelExport>
        </Box>
      </Box>
    </>
  )
}

export default CorporateSOP
