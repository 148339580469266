export const HOME = '/'
export const LOGIN = '/login'
export const SEARCH = '/search'
export const ACCESSDENIED = '/accessdenied'
export const KNOWLEDGE_BASE = 'https://kb.everchain.com/knowledge'
export const HELP_REQUEST = 'https://kb.everchain.com/knowledge/kb-tickets/new'
export const DOCUMENT_REGISTRATION = '/document-registration'
export const DOCUMENT_SETUP = '/document-setup'
export const DOCUMENT_PORTAL = '/document-portal'
export const CERTIFICATION_EVENTS = '/certification-events'
export const DASHBOARD = '/dashboard'
export const DOCUMENT_REPOSITORY = '/document-repository'
export const THIRD_PARTY_REPOSITORY = '/third-party-repository'
export const DIGITAL_SELLER_SURVEY_DETAIL = '/digital-seller-survey/detail'
export const DIGITAL_SELLER_SURVEY = '/digital-seller-survey'
export const EXTERNAL_DIGITAL_SELLER_SURVEY_DETAIL =
  '/external-digital-seller-survey/detail'
export const SURVEY_BUILDER = '/survey-builder'
export const CERTIFICATION_MANAGER = '/certification-manager'
export const CORPORATE_SOP = '/corporate-sop'
export const CORPORATE_SOP_DETAIL = '/corporate-sop-detail'
